import React, {createContext, useState, useEffect} from "react";
import axios from "axios"
import {useCookies} from "react-cookie";

export const UserContext = createContext(true);


export const UserProvider = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const [logged_in, set_logged_in] = useState(null);
    const [cookies, setCookie, removeCookies] = useCookies();
    const [user, set_user] = useState(null);
    const [my_tasks, set_my_tasks] = useState([])
    const [show_my_tasks, set_show_my_tasks] = useState(false)

    const check_token = () => {
        if(cookies.token){
            set_logged_in(cookies.token)
        }
    }

    const log_in_user = async (email, password) => {
        const res = await axios({
            method: "post",
            url: `${API_URL}/login/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email: email,
                password: password
            }
        })

        setCookie('token', res.data.token, {path: '/', sameSite:"strict", maxAge:86400})
        set_logged_in(res.data.token)
    }

    const log_out_user = async () =>{
        const res = await axios({
            method: "get",
            url: `${API_URL}/logout/`,
            headers : {
                authorization: `${logged_in}`
            },
            withCredentials: true
        })

        removeCookies("token", {path: "/"})
        set_logged_in(null)
    }

    const fetch_user = async () => {
        const res = await axios({
            method: "get",
            url: `${API_URL}/user/`,
            headers : {
                authorization: `${logged_in}`
            },
            withCredentials: true
        })
        set_user(res.data)
    }

    const fetch_tasks = async () => {
        const res = await axios({
            method: "get",
            url: `${API_URL}/user/tasks/`,
            headers : {
                authorization: `${logged_in}`
            },
            withCredentials: true
        })
        set_my_tasks(res.data)
    }

    useEffect(() => {
        if(logged_in){
            fetch_user()
            fetch_tasks()
        }
    }, [logged_in])

    return (
        <UserContext.Provider
            value={{
                logged_in: logged_in,
                user: user,
                log_in_user: log_in_user,
                log_out_user: log_out_user,
                check_token: check_token,
                set_logged_in: set_logged_in,
                my_tasks: my_tasks,
                show_my_tasks:show_my_tasks,
                set_show_my_tasks: set_show_my_tasks
            }}>

            {props.children}

        </UserContext.Provider>
    )
};