import React, {useContext, useEffect, useState, Fragment} from 'react';
import axios from "axios"
import {useParams, Link} from "react-router-dom"
import Header from "../Header";
import {UserContext} from "../contexts/UserContext";

import {ReactComponent as SubscribeIcon} from "../../images/alarm_icon.svg"
import {ReactComponent as IssueIcon} from "../../images/issue.svg"
import {ReactComponent as LockedIcon} from "../../images/lock_asset.svg"
import CloseIcon from "../../images/close.svg"
import FolderIcon from "../../images/folder.svg"
import TagIcon from "../../images/tags_icon.svg"
import Tasks from "./Tasks";
import ConceptViewer from "./ConceptViewer";
import {DetailsContext} from "../contexts/DetailsContext";


function Divider({title}) {
    return (
        <div className="divider-layout">
            <small>{title}</small>
            <hr/>
        </div>
    )
}

function Description({description}) {
    return (
        <div dangerouslySetInnerHTML={{ __html: description}} />
    )

}

function Concepts({concept_list}){
    const [view_concept, set_view_concept] = useState(false)
    const [current_index, set_current_index] = useState(0)
    const IMAGE_SERVER = process.env.REACT_APP_API_ROOT

    return (
        <div className="concept-layout">
            <ConceptViewer concept_list={concept_list} view_concept={view_concept} set_view_concept={set_view_concept} current_index={current_index}/>

            {concept_list &&
                concept_list.map((concept, index) =>
                    <div onClick={() => {
                        set_view_concept(true)
                        set_current_index(index)
                    }}
                         className="thumbnail"
                         key={index}
                         style={{backgroundImage: `url(${IMAGE_SERVER}${concept})`}}
                    />
                )
            }
        </div>
    )
}

function Tag({title}){
    return (
        <div className="tag">
            <img src={TagIcon} alt=""/>
            <small>{title}</small>
        </div>
    )
}

function SubscribeToAsset(){
    const {user} = useContext(UserContext)
    const {asset_data, subscribe_action} = useContext(DetailsContext)

    const is_subscribed = () => {
        if(!user){return}

        if(asset_data && asset_data.followers){
            return asset_data.followers.includes(user.id)
        }else{
            return false
        }
    }

    const click_handler = () => {
        if(is_subscribed()){
            subscribe_action(false)
        }else{
            subscribe_action(true)
        }
    }

    return(
        <SubscribeIcon title="Subscribe" className={`icon-button ${is_subscribed()? "active-icon":""}`} onClick={click_handler}/>
    )
}


function AssetDetails(props) {
    const IMAGE_SERVER = process.env.REACT_APP_API_ROOT
    const {loading, asset_data, fetch_data} = useContext(DetailsContext)
    const { asset_id } = useParams();

    useEffect(() => {
        fetch_data(asset_id)
    }, [])

    if(loading){
        return null
    }

    return (
        <div>
            <Header details/>

            <div className="content-container">
                <div className="details-container">
                    <div className="header">
                        {/*Asset name, project, type*/}
                        <div className="asset-name-layout">
                            <h3 className="asset-name">{asset_data.name}</h3>
                            <small>{asset_data.project} / {asset_data.type}</small>
                        </div>

                        {/*Asset icons*/}
                        {/*<img src={FolderIcon} alt="" title="Files" className="icon-button"/>*/}
                        <SubscribeToAsset/>
                        <IssueIcon title="Issue" className={`icon-button ${asset_data.issue? "active-icon": ""} inactive-button`}/>
                        <LockedIcon title="Finished" className={`icon-button ${asset_data.locked? "active-icon": ""} inactive-button`}/>

                        <Link to="/">
                            <img src={CloseIcon} alt="" className="icon-button close" title="Close"/>
                        </Link>
                    </div>

                    <div className="main-columns">
                        <div className="left-column">
                            <div className="thumbnail-container">
                                <img src={`${IMAGE_SERVER}${asset_data.thumbnail}`} alt=""/>
                            </div>

                            <hr/>
                            <div>Priority: {asset_data.priority}</div>

                            <Divider title={"Stages"}/>

                            {
                                asset_data.stages && <Tasks stages={asset_data.stages}/>
                            }

                            <Divider title={"Tags"}/>

                            <div className="tags-container">
                                {
                                    asset_data.tags &&
                                    asset_data.tags.map(tag => <Tag key={tag} title={tag}/>)
                                }
                            </div>
                        </div>

                        <div className="center-column">
                            <Description description={asset_data.description}/>

                            {
                                asset_data.concepts && asset_data.concepts.length > 0&&
                                    <Fragment>
                                        <Divider title={"Concepts"}/>

                                        <Concepts concept_list={asset_data.concepts}/>
                                    </Fragment>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssetDetails;