import React from 'react';
import {Link} from "react-router-dom";

import PrioritySuperhighIcon from "../../images/priority_superhigh_icon.svg"
import PriorityHighIcon from "../../images/priority_high_icon.svg"
import PriorityMediumIcon from "../../images/priority_medium_icon.svg"
import PriorityLowIcon from "../../images/priority_low_icon.svg"
import NoThumbnail from  '../../images/camera.svg'

function AssetCard({data}) {
    const IMAGE_SERVER = process.env.REACT_APP_API_ROOT
    const {name, id, priority, thumbnail, project, type, color, state} = data

    const icons = {
        superhigh: PrioritySuperhighIcon,
        high: PriorityHighIcon,
        medium: PriorityMediumIcon,
        low: PriorityLowIcon
    }

    return (
        <Link to={`/assets/${id}`} className="asset-card">
            <div className="content">
                <div className="thumbnail-container" style={{backgroundImage: `url(${IMAGE_SERVER}${thumbnail})`}}>
                    <img src={icons[priority]} alt="" className="priority-icon"/>

                    {
                        !thumbnail&& <img className="no-thumbnail" src={NoThumbnail} alt=""/>
                    }

                    {
                        state&& <div className="footer">
                            <small>{state}</small>
                            <div className="state-color" style={{backgroundColor: color}}/>
                        </div>
                    }

                </div>

                <div>
                    <p>{name}</p>
                    {
                        project&& <small>{project} / {type}</small>
                    }

                </div>
            </div>
        </Link>
    );
}

export default AssetCard;