import React, {useContext, useRef, useEffect} from 'react';
import AssetCard from "./AssetCard";
import {AssetListContext} from "../contexts/AssetListContext";

function AssetList(props) {
    const {asset_list, fetch_data} = useContext(AssetListContext)
    const grid_view = useRef(null)

    useEffect(() => {
        fetch_data()
    }, [])

    return (
        <div className="asset-list-grid">
            {
                asset_list.map(item => <AssetCard data={item} key={item.id}/>)
            }
        </div>
    );
}

export default AssetList;