import React, {useContext, useState} from 'react';
import {useHistory, useLocation } from "react-router-dom";
import {UserContext} from "./contexts/UserContext";
import NoThumbnail from "../images/camera.svg";
import {DetailsContext} from "./contexts/DetailsContext";
import CloseIcon from "../images/close.svg";

function TaskItem({data}){
    const {set_show_my_tasks} = useContext(UserContext)
    const {fetch_data} = useContext(DetailsContext)
    const {name, id, thumbnail, project, type} = data
    const IMAGE_SERVER = process.env.REACT_APP_API_ROOT
    const history = useHistory()
    const location = useLocation();

    const go_to_asset = () => {
        set_show_my_tasks(false)
        if(location.pathname.includes("/assets/")){
            fetch_data(id)
        }else{
            history.push(`/assets/${id}`)
        }
    }

    return(
        <div className="task-item" onClick={go_to_asset}>
            <div className="thumbnail-container" style={{backgroundImage: `url(${IMAGE_SERVER}${thumbnail})`}}>
                {
                    !thumbnail&& <img className="no-thumbnail" src={NoThumbnail} alt=""/>
                }
            </div>

            <div className="details">
                <h2>{name}</h2>
                <small>{project} / {type}</small>
            </div>

        </div>
    )
}

function UserDialog(props) {
    const {my_tasks, show_my_tasks, set_show_my_tasks} = useContext(UserContext)

    if(!show_my_tasks){
        return null
    }

    return (
        <div className="check-out-container">
            <div className="check-out-dialog">
                <div className="header">
                    <h2> My Tasks</h2>
                    <img src={CloseIcon} alt="" className="icon-button close" title="Close" onClick={() =>set_show_my_tasks(false)}/>
                </div>

                <hr/>

                <div className="my-task-list">
                    {
                        my_tasks.map(data => <TaskItem key={data.id} data={data}/>)
                    }
                </div>
            </div>
        </div>
    );
}

export default UserDialog;