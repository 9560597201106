import React, {createContext, useState, useEffect, useContext} from "react";
import axios from "axios";
import queryString from "query-string"
import {UserContext} from "./UserContext";

export const AssetListContext = createContext(true);

export const AssetListProvider = (props) => {
    const [asset_list, set_asset_list] = useState([])
    const [total_pages, set_total_pages] = useState(1)
    const [loading, set_loading] = useState(true)
    const [current_page, set_current_page] = useState(1)
    const {logged_in, set_logged_in} = useContext(UserContext)

    // filters
    const [projects, set_projects] = useState([])
    const [types, set_types] = useState([])
    const [states, set_states] = useState([])
    const [stages, set_stages] = useState([])
    const [tags, set_tags] = useState([])
    const [name, set_name] = useState("")

    const API_URL = process.env.REACT_APP_API_URL

    const fetch_data = async () => {
        if(!logged_in) return

        const query_string = query_gen()
        try{
            const res = await axios({
                method: "get",
                url: `${API_URL}/assets/${query_string}`,
                headers : {
                    authorization: `${logged_in}`
                },
                withCredentials: true
            })

            set_asset_list(res.data.asset_list)
            set_total_pages(res.data.pages)
        }catch (err){
            set_logged_in(null)
        }
    }

    const fetch_more = async () => {
        if(loading) return
        if(!logged_in) return

        let next_page = current_page + 1
        if(next_page <= total_pages){
            set_loading(true)
            set_current_page(next_page)

            const query_string = query_gen().replace("?", "&")

            const res = await axios({
                method: "get",
                url: `${API_URL}/assets/?page=${next_page}${query_string}`,
                headers : {
                    authorization: `${logged_in}`
                },
                withCredentials: true
            })

            let new_asset_list = asset_list.concat(res.data.asset_list)

            set_asset_list(new_asset_list)
        }
    }

    const query_gen = () => {
        const filer_list = {}

        if(projects.length > 0){
            filer_list.projects = projects.toString()
        }

        if(types.length > 0){
            filer_list.types = types.toString()
        }

        if(states.length > 0){
            filer_list.states = states.toString()
        }

        if(stages.length > 0){
            filer_list.stages = stages.toString()
        }

        if(tags.length > 0){
            filer_list.tags = tags.toString()
        }

        if(name.length > 0){
            filer_list.name = name
        }

        const query = queryString.stringify(filer_list)

        if(query.length > 0){
            return `?${query}`
        }else{
            return ""
        }
    }

    useEffect(() => {
        fetch_data()
    }, [logged_in, projects, types, states, stages, tags, name])

    return (
        <AssetListContext.Provider
            value={{
                asset_list: asset_list,
                set_asset_list: set_asset_list,
                loading: loading,
                fetch_data: fetch_data,

                projects: projects,
                set_projects: set_projects,
                types: types,
                set_types: set_types,
                states: states,
                set_states: set_states,
                stages: stages,
                set_stages: set_stages,
                tags: tags,
                set_tags: set_tags,
                name: name,
                set_name: set_name
            }}>

            {props.children}

        </AssetListContext.Provider>
    )
};