import React, {useContext, useEffect, useState} from 'react';
import {DetailsContext} from "../contexts/DetailsContext";
import CloseIcon from "../../images/close.svg";
import {AssetListContext} from "../contexts/AssetListContext";

function DropArea({file, set_file}){
    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const handleFiles = (files) => {
        const validated_files = []
        for(let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                validated_files.push(files[i])
            } else {
                // add a new property called invalid
                // add to the same array so we can display the name of the file
                // set error message
            }
        }

        if(validated_files.length > 0){
            set_file(validated_files[0])
        }
    }

    const validateFile = (file) => {
        const validTypes = ['application/x-zip-compressed'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    return (
        <div className="drop-area"
             onDragOver={dragOver}
             onDragEnter={dragEnter}
             onDragLeave={dragLeave}
             onDrop={fileDrop}
        >
            {
                file? <div className="file-name-container"><h2>{file.name}</h2> <small>{fileSize(file.size)}</small></div> : <h2>Drop your file here (.zip)</h2>
            }
        </div>
    )
}

function UploadProgress({file}){
    const {progress} = useContext(DetailsContext)

    return(
        <div className="upload_progress_container">
            <div className="progressbar" style={{width: `${progress}%`}} />
            <small className="progress-text">Uploading: {file.name} {progress}%</small>
        </div>
    )
}

function CheckOut() {
    const {asset_data, set_check_out_data, check_out_data, check_out, uploading} = useContext(DetailsContext)
    const {} = useContext(AssetListContext)
    const [comment, set_comment] = useState("")
    const [file, set_file] = useState(null)
    const [errors, set_errors] = useState(null)

    if(!check_out_data){
        return null
    }

    const check_out_action = async (e) => {
        e.preventDefault()

        if(!file){
            set_errors("You most add a file to your task.")
            setTimeout(()=> set_errors(null), 3000)
        }else{
            await check_out({
                comment: comment,
                file: file,
                stage_id: check_out_data.stage_id,
                task: check_out_data.task,
            })

            set_file(null)
            set_errors(null)
            set_comment("")
        }
    }

    const close_action = () => {
        set_check_out_data(null)
        set_file(null)
        set_errors(null)
        set_comment("")
    }

    return (
        <div className="check-out-container">
            <form className="check-out-dialog">
                <div className="header">
                    <h2> Check Out: {asset_data.name} ({check_out_data.name})</h2>
                    <img src={CloseIcon} alt="" className="icon-button close" title="Close" onClick={close_action}/>
                </div>

                <hr/>

                <div className="comment">
                    <textarea name="" placeholder="Add comment (optional)..." rows={10} value={comment} onChange={e => set_comment(e.target.value)}/>
                </div>

                <hr/>

                <div className="attachment">
                    <DropArea file={file} set_file={set_file}/>
                </div>

                <hr/>

                {
                    errors && <small className="error">{errors}</small>
                }

                {
                    uploading? <UploadProgress file={file}/> : <button onClick={check_out_action}>Check Out</button>
                }


            </form>
        </div>
    );
}

export default CheckOut;