import React, {useState, useEffect, useContext} from 'react';
import axios from "axios"

import CategoryBox from "./CategoryBox";
import ProjectIcon from "../../images/projects_icon.svg"
import TypeIcon from "../../images/types_icon.svg"
import StateIcon from "../../images/states_icon.svg"
import TasksIcon from "../../images/tasks_icon.svg"
import TagsIcon from "../../images/tags_icon.svg"
import {AssetListContext} from "../contexts/AssetListContext";
import {UserContext} from "../contexts/UserContext";
import Arrow from "../../images/arrow_big.svg";


function SidePanel(props) {
    const [categories, set_categories] = useState({})
    const [loading, set_loading] = useState(true)
    const API_URL = process.env.REACT_APP_API_URL
    const {logged_in, set_logged_in, user} = useContext(UserContext)
    const [opened, set_opened] = useState(false)

    const {
        projects,
        set_projects,
        types,
        set_types,
        states,
        set_states,
        stages,
        set_stages,
        tags,
        set_tags
    } = useContext(AssetListContext)

    const fetch_data = async () => {
        try{
            const res = await axios({
                method: "get",
                url: `${API_URL}/categories/`,
                headers : {
                    authorization: `${logged_in}`
                },
                withCredentials: true
            })

            set_categories(res.data)
            set_loading(false)
        }catch(err){
            set_logged_in(false)
        }
    }

    useEffect(() => {
        fetch_data()
    }, [])

    if(loading){
        return <div className="side-panel-container"/>
    }

    return (
        <div className="side-panel-container">
            <img src={Arrow} alt="" className={`arrow ${opened? "down" : ""}`} onClick={() => set_opened(!opened)}/>

            <CategoryBox icon={ProjectIcon} title="Projects" items={categories.projects} filter_list={projects} setter={set_projects} global_open={opened}/>
            <CategoryBox icon={TypeIcon} title="Types" items={categories.types} filter_list={types} setter={set_types} global_open={opened}/>
            <CategoryBox icon={StateIcon} title="States" items={categories.states} filter_list={states} setter={set_states} global_open={opened}/>
            <CategoryBox icon={TasksIcon} title="Tasks" items={categories.stages} filter_list={stages} setter={set_stages} global_open={opened}/>
            <CategoryBox icon={TagsIcon} title="Tags" items={categories.tags} filter_list={tags} setter={set_tags} global_open={opened}/>
        </div>
    );
}

export default SidePanel;