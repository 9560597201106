import React, {useState, useEffect} from 'react';
import Arrow from "../../images/arrow_big.svg"

function CategoryItem(props){
    const {data, set_selected} = props

    return (
        <div className={`item-container ${data.selected? "selected":""}`} onClick={(e) => set_selected(e, data.id)}>
            <div className={`white-box ${data.selected? "selected" : ""}`} />

            <div className="item">
                {data.name}
            </div>
        </div>
    )
}

function CategoryBox({icon, title, items, setter, global_open}) {
    const [opened, set_opened] = useState(false)
    const [categories, set_categories] = useState(items)

    const set_selected = (e, item_id) =>{
        const new_categories = [...categories]

        if(e.ctrlKey){
            for(let i=0; i< new_categories.length; i++){
                let item = new_categories[i]
                if(item.id === item_id){
                    item.selected = true
                }
            }
        }else{
            for(let i=0; i< new_categories.length; i++){
                let item = new_categories[i]
                if(item.selected){
                    item.selected = false
                }else{
                    item.selected = item.id === item_id;
                }
            }
        }
        setter(new_categories.filter(i => i.selected).map(i => i.name))

        set_categories(new_categories)
    }

    useEffect(() => {
        set_opened(global_open)
    }, [global_open])

    return (
        <div className="category-box">

            <div className="header" onClick={() => set_opened(!opened)}>
                <div style={{display:"flex"}}>
                    <img src={icon} alt="" className="icon"/>
                    <p>{title}</p>
                </div>

                <img src={Arrow} alt="" className={`arrow ${opened? "down" : ""}`}/>
            </div>

            <div className={`body ${opened? "opened" : ""}`}>
                {
                    categories.map(item => <CategoryItem data={item} key={item.id} set_selected={set_selected}/>)
                }
            </div>
        </div>
    );
}

export default CategoryBox;