import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import {UserProvider} from "./components/contexts/UserContext";
import {AssetListProvider} from "./components/contexts/AssetListContext";
import AssetDetails from "./components/asset_details/AssetDetails";
import {DetailsProvider} from "./components/contexts/DetailsContext";


function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <UserProvider>
                    <Route path="/login" component={Login}/>

                    <AssetListProvider>
                        <DetailsProvider>
                            <ProtectedRoute path="/assets/:asset_id" component={AssetDetails}/>
                            <ProtectedRoute exact path="/" component={Home}/>
                        </DetailsProvider>
                    </AssetListProvider>

                </UserProvider>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
