import React, {Fragment} from 'react';
import Header from "./Header";
import SidePanel from "./side_panel/SidePanel";
import AssetList from "./asset_list/AssetList";

function Home(props) {
    return (
        <Fragment>
            <Header/>

            <div className="content-container">
                <div className="asset-list-container">
                    <SidePanel/>
                    <AssetList/>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;