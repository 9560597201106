import React, {useContext, useState, useEffect} from 'react';
import Logo from "../images/logo_grey.svg"
import {UserContext} from "./contexts/UserContext";


function Login(props) {
    const {logged_in, log_in_user} = useContext(UserContext)
    const [email, set_email] = useState("")
    const [password, set_password] = useState("")

    useEffect(() => {
        if(process.env.NODE_ENV === 'development'){
            set_email('csilla@gmail.com')
            set_password("bGaXQMyh")
        }
    }, [])

    useEffect(() => {
        if(logged_in){
            props.history.push("/")
        }
    }, [logged_in])

    const send_login = (e) => {
        e.preventDefault()
        log_in_user(email, password)
    }

    return (
        <div className="center-page">
            <form className="form-container">
                <div className="header">
                    <img src={Logo} alt=""/>
                    <h2>Asset Core | Login</h2>
                </div>

                <hr/>
                <input type="email" placeholder="Email" value={email} onChange={(e) => set_email(e.target.value)}/>
                <input type="password" placeholder="Password" value={password} onChange={(e) => set_password(e.target.value)}/>
                <hr/>
                <button onClick={send_login}>Log In</button>
            </form>
        </div>
    );
}

export default Login;