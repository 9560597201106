import React, {useContext} from 'react';
import MagnifierIcon from "../images/search.svg"
import Logo from "../images/logo_grey.svg"
import TaskIcon from "../images/tasks_icon.svg"
import AlarmIcon from "../images/alarm_icon.svg"
import LogoutIcon from "../images/logout.svg"
import {AssetListContext} from "./contexts/AssetListContext";
import {UserContext} from "./contexts/UserContext";
import UserDialog from "./UserDialog";


function Header(props) {
    const {name, set_name} = useContext(AssetListContext)
    const {log_out_user, set_show_my_tasks} = useContext(UserContext)
    const {details} = props

    return (
        <div className="header-container">
            <div className="site-name-container">
                <img src={Logo} alt=""/>
                <h2>Asset Core Web</h2>
            </div>

            <UserDialog/>

            {!details&&
                <div className="search-container">
                    <div className="icon-container">
                        <img src={MagnifierIcon} alt=""/>
                    </div>

                    <input type="text" value={name} onChange={e => set_name(e.target.value)}/>
                </div>
            }

            <div className="buttons-layout">
                <img src={TaskIcon} alt="" className="icon-button" title="My tasks" onClick={()=>set_show_my_tasks(true)}/>
                {/*<img src={AlarmIcon} alt="" className="icon-button" title="Notifications"/>*/}
                <img src={LogoutIcon} alt="" className="icon" title="Log out" onClick={() => log_out_user()}/>
            </div>
        </div>
    );
}

export default Header;