import React, {useContext, Fragment} from 'react';
import {UserContext} from "../contexts/UserContext";
import {DetailsContext} from "../contexts/DetailsContext";
import CheckOut from "./CheckOut";

function Stage(props) {
    const {user} = useContext(UserContext)
    const {check_in, set_check_out_data} = useContext(DetailsContext)
    const {name, current_state, assignee, stage_id} = props.data

    const colors = {
        "": "#1c1c1c",
        "work ready": "#5caafd",
        "assigned": "#fedf46",
        "review": "#f16d25"
    }

    const check_in_button = () => {
        if(!user || !user.departments){return}

        if(current_state === "work ready" && user.departments.includes(name)){
            return  true
        }
    }

    const check_out_button = () => {
        if(!user || !assignee){return}

        if(current_state === "assigned" && user.name === assignee.name){
            return  true
        }
    }

    return (
        <div className="stage-item" style={{backgroundColor: colors[current_state], color: `${current_state.length > 0? '#1c1c1c': ''}`}}>
            <div style={{display:"flex"}}>
                {name}

                {
                    assignee &&
                        <div style={{marginLeft:"5px"}}>| {assignee.name}</div>
                }
            </div>


            {
                check_in_button() && <div className="task-action-button" style={{color:colors[current_state]}} onClick={()=> check_in(stage_id)}>Check In</div>
            }

            {
                check_out_button() && <div className="task-action-button" style={{color:colors[current_state]}} onClick={()=> set_check_out_data(props.data)}>Check Out</div>
            }

        </div>
    )
}


function Tasks({stages}) {
    return (
        <div className="stages-container">
            {
                stages.map(stage => <Stage key={stage.name} data={stage}/>)
            }
            <CheckOut/>
        </div>
    );
}

export default Tasks;