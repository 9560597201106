import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {UserContext} from "./UserContext";
import { useHistory } from 'react-router-dom'

export const DetailsContext = createContext(true);

export const DetailsProvider = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const {logged_in} = useContext(UserContext)
    const [asset_data, set_asset_data] = useState({})
    const [loading, set_loading] = useState(false)
    const [check_out_data, set_check_out_data] = useState(null)
    const [uploading, set_uploading] = useState(false)
    const [progress, set_progress] = useState(0)

    const fetch_data = async (asset_id) => {
        set_loading(true)

        const res = await axios({
            method: "get",
            url: `${API_URL}/assets/${asset_id}/`,
            headers : {
                authorization: `${logged_in}`,
            },
            withCredentials: true
        })

        set_loading(false)
        set_asset_data(res.data)
    }

    const subscribe_action = async (value) => {
        const querystring = value? "?subscribe=true" : ""
        const res = await axios({
            method: "get",
            url: `${API_URL}/assets/subscribe/${asset_data.id}/${querystring}`,
            headers : {
                authorization: `${logged_in}`
            },
            withCredentials: true
        })

        set_asset_data(res.data)
    }

    const check_in = async (stage_id) => {
        const res = await axios({
            method: "post",
            url: `${API_URL}/assets/${asset_data.id}/checkin/`,
            headers : {
                'Content-Type': 'application/json',
                authorization: `${logged_in}`
            },
            withCredentials: true,
            data: {
                stage_id: stage_id
            }
        })

        set_asset_data(res.data)
    }

    const upload_progress = (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        set_progress(percentCompleted)
    }

    const check_out = async (task_data) => {
        set_uploading(true)
        const formData = new FormData()
        formData.append("comment", task_data.comment)
        formData.append('task_file', task_data.file);
        formData.append('stage_id', task_data.stage_id);
        formData.append('task', task_data.task);

        const res = await axios({
            method: 'post',
            url: `${API_URL}/assets/${asset_data.id}/checkout/`,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data', authorization: `${logged_in}`},
            withCredentials: true,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            onUploadProgress: upload_progress,
            })

        set_asset_data(res.data)
        set_uploading(false)
        set_progress(0)
        set_check_out_data(null)
    }

    return (
        <DetailsContext.Provider
            value={{
                loading:loading,
                asset_data:asset_data,
                set_asset_data:set_asset_data,

                check_out_data: check_out_data,
                set_check_out_data: set_check_out_data,

                uploading:uploading,
                progress:progress,

                fetch_data:fetch_data,
                subscribe_action:subscribe_action,
                check_in:check_in,
                check_out:check_out
            }}>

            {props.children}

        </DetailsContext.Provider>
    )
};