import React, {useEffect, useState} from "react";
import CloseIcon from "../../images/close.svg";

function ConceptViewer({concept_list, view_concept, set_view_concept, current_index}) {
    const IMAGE_SERVER = process.env.REACT_APP_API_ROOT
    const [current, set_current] = useState(0)

    useEffect(()=> {
        set_current(current_index)
    }, [current_index])

    if(!view_concept){
        return null
    }

    const key_handler = (e) => {
        let idx = current
        if(e.key === "Escape"){
            close_viewer()
            return
        }

        if(e.key === "ArrowRight"){
            idx = current + 1
            if(idx > concept_list.length -1){
                idx = 0
            }
            set_current(idx)

        }else if(e.key === "ArrowLeft"){
            idx = current - 1

            if(idx < 0){
                idx = concept_list.length -1
            }
            set_current(idx)
        }
    }

    document.onkeydown = key_handler

    const close_viewer = () => {
        document.onkeydown = null
        set_view_concept(false)
    }

    return (
        <div className="concept-viewer-container">
            <img src={CloseIcon} alt="" className="icon-button" onClick={close_viewer}/>

            <div className="preview-container">
                <img src={`${IMAGE_SERVER}${concept_list[current]}`} alt="" className="preview"/>
            </div>

            <div className="concept-navigator-layout">
                {
                    concept_list.map((item, index) =>
                        <div
                            className={`dot ${index === current? "current": ""}`}
                            key={index}
                            onClick={() => set_current(index)}
                        />
                        )
                }
            </div>
        </div>
    )
}

export default ConceptViewer